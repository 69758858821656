import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App).mount('#app')


const app = createApp(App)
app.use(ElementPlus)
app.config.globalProperties.$axios = axios
app.use(router)
app.mount('#app')


axios.defaults.baseURL = "http://specialwear.xyz/"
// 获取当前网页的主机名
const hostname = window.location.hostname;
var regex = /^116\.198\.205\.\d{1,3}$/;
if (regex.test(hostname)) {
    axios.defaults.baseURL = "http://116.198.205.140"
}