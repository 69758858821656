
import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [

        {
            path: '/',
            redirect: '/test', 
        },
        {
            path: '/test',
            name: 'test',
            component: () => import('../views/WearTest.vue'),
            meta: { requiresAuth: true },
        },        {
            path: '/test2',
            name: 'test2',
            component: () => import('../views/WearQuerySkins.vue'),
            meta: { requiresAuth: true },
        },
    ]
});

export default router
